//
// Root
//

// Light mode
@include color-mode(light) {
    // Separator
    --#{$prefix}app-separator-border: #{$app-separator-border};

    // Sidebar base     
    --#{$prefix}app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color};
}

// Dark mode
@include color-mode(dark) {
	// Separator
    --#{$prefix}app-separator-border: #{$app-separator-border-dark};

    // Sidebar base     
    --#{$prefix}app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color-dark};
}
