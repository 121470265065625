//
// Variables
//

// Custom
$app-separator-border: 1px solid $gray-300;
$app-separator-border-dark: 1px solid $gray-200-dark;

// Reboot
$app-bg-color: $body-bg;
$app-bg-color-dark: $coal-400;

// General
$app-general-root-font-size-desktop: 13px;
$app-general-root-font-size-tablet: 12px;
$app-general-root-font-size-mobile: 12px;

// Container
$app-container-padding-x: 40px !default;
$app-container-padding-x-mobile: 20px !default;

// Content
$app-content-padding-y: 30px;
$app-content-padding-y-mobile: 20px;

// Header base
$app-header-base-height: 80px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: $body-bg;
$app-header-base-bg-color-dark: $coal-400;

// Header minimize
$app-header-minimize-height: 80px;
$app-header-minimize-height-mobile: 60px;
$app-header-minimize-bg-color: rgba($body-bg, 0.5);
$app-header-minimize-bg-color-dark: rgba($coal-400, 0.5);
$app-header-minimize-backdrop-filter: blur(6px);
$app-header-minimize-backdrop-filter-mobile: blur(6px);

// Sidebar base
$app-sidebar-base-width: 280px;
$app-sidebar-base-height: 80px; 
$app-sidebar-base-separator-color: 1px solid $gray-300;
$app-sidebar-base-bg-color: $body-bg;
$app-sidebar-base-bg-color-dark: $body-bg-dark;
$app-sidebar-base-box-shadow: none;
$app-sidebar-base-box-shadow-dark: none;
$app-sidebar-base-toggle-btn-bg-color: $body-bg;
$app-sidebar-base-toggle-btn-bg-color-dark: #2A2A3C;

$app-sidebar-base-menu-link-title: #9BA0B8;
$app-sidebar-base-menu-link-icon: #C5C8D4;
$app-sidebar-base-menu-link-icon-dark: $white;
$app-sidebar-base-menu-link-active: #1E1F27; 

// Sidebar minimize
$app-sidebar-minimize-width: 85px;
$app-sidebar-base-menu-link-minimize-width: 45px;

// Footer
$app-footer-height: auto;
$app-footer-height-mobile: auto;

// Scrolltop
$scrolltop-bottom: 43px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-bg-color: $body-bg;
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;