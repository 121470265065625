//
// Sidebar Minimize
//

.app-sidebar-logo-minimize {
    display: none;
}  
    
// Desktop sidebar minimize mode
@include app-layout-minimize(app-sidebar) {
    .app-sidebar-logo {        
        display: flex;
        align-items: center;
        justify-content: center;       
        height: $app-sidebar-base-height;        

        .app-sidebar-logo-default {
            display: none;
        }

        .app-sidebar-logo-minimize {
            display: inline-block;                         
        }         
    }

    .app-sidebar-wrapper {
        width: var(--#{$prefix}app-sidebar-width-actual); 
    } 
    
    .app-sidebar-user-default {
        display: none !important;
    }

    .app-sidebar-user-minimize {
        display: none !important;
    }

    .app-sidebar-menu {        
        .menu-content,
        .menu-title {
            opacity: 0;
            @include app-layout-transition(opacity);
        }

        .menu-item { 
            .menu-link {  
                margin: 0 4.3px;
                padding: 0 9.3px; 
                width: $app-sidebar-base-menu-link-minimize-width !important;
                height: $app-sidebar-base-menu-link-minimize-width !important;
            }
        }
         
        .menu-item.show > .menu-sub {
            height: 0;
            overflow: hidden;
            @include app-layout-transition(height);
        }
    } 
}