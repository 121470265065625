//
// Sidebar
//

.app-sidebar {
    border-right: var(--#{$prefix}app-separator-border);

    .hover-scroll-y {        
        @include scrollbar-color(var(--#{$prefix}gray-200), var(--#{$prefix}gray-300));
    }     

    .menu {
        @include menu-link-default-state( 
            $title-color: var(--#{$prefix}gray-600), 
            $icon-color: var(--#{$prefix}gray-600), 
            $bullet-color: var(--#{$prefix}gray-500), 
            $arrow-color: var(--#{$prefix}gray-500), 
            $bg-color: null,
            $all-links: true
        );
        
        @include menu-link-hover-state( 
            $title-color: var(--#{$prefix}gray-800), 
            $icon-color: var(--#{$prefix}gray-700), 
            $bullet-color: var(--#{$prefix}gray-700),  
            $arrow-color: var(--#{$prefix}gray-700), 
            $bg-color: null,
            $all-links: true
        );
        
        @include menu-link-show-state( 
            $title-color: var(--#{$prefix}gray-800), 
            $icon-color: var(--#{$prefix}gray-700), 
            $bullet-color: var(--#{$prefix}gray-700),  
            $arrow-color: var(--#{$prefix}gray-700), 
            $bg-color: null,
            $all-links: true
        );
        
        @include menu-link-here-state( 
            $title-color: var(--#{$prefix}gray-800), 
            $icon-color: var(--#{$prefix}gray-700), 
            $bullet-color: var(--#{$prefix}gray-700),  
            $arrow-color: var(--#{$prefix}gray-700), 
            $bg-color: null,
            $all-links: true
        );

        @include menu-link-active-state( 
            $title-color: var(--#{$prefix}primary), 
            $icon-color: var(--#{$prefix}primary), 
            $bullet-color: var(--#{$prefix}primary), 
            $arrow-color: var(--#{$prefix}white), 
            $bg-color: var(--#{$prefix}primary-light),
            $all-links: true
        );
        
        > .menu-item {
            > .menu-link {
                margin: 0 8px 3px 8px;
                padding: 10px;

                > .menu-title {
                    font-size: 15px;
                    padding-left: 2px;
                }
            }
        }
    }       
} 

// Desktop mode
@include media-breakpoint-up(lg) {
    .app-sidebar {         
        .app-sidebar-logo {
            height: $app-header-base-height; 
            padding: 30px;       
        }  
    }

    .app-sidebar-toggle {         
        background-color: var(--#{$prefix}app-sidebar-base-toggle-btn-bg-color) !important; 

        .active .svg-icon {
            transform: rotateZ(0deg) !important;
        }        
    }

    [dir="rtl"] {
        .app-sidebar-toggle {
            .svg-icon {
                transform: rotateZ(180deg);
            }

            &.active .svg-icon {
                transform: rotateZ(0deg) !important;
            }
        }
    } 
} 

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
    .app-sidebar-toggle {
        display: none !important;
    }
 
    .app-sidebar-logo-default {
        display: none !important;
    }           
} 