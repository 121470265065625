//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Border Radiues
$border-radius-sm:            						.55rem;
$border-radius:               						.65rem;
$border-radius-lg:            						1rem;
$border-radius-xl:                                  1.25rem;